<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <div class="manage-accounting my-3" style="padding: 0.4em">
      <div class="main">
        <p class="company-name">{{ companyName }}</p>
        <div class="account-card">
          <p>{{ $t("depreciation.account_period_start") }}</p>

          <date-picker
            class="inline-block h-full"
            locale="en"
            v-model="selectedDate"
            :max-date="new Date()"
            :disabled-dates="{
              days: DISABLED_DATES,
            }"
            :masks="masks"
            is-required
          >
            <template v-slot="{ inputValue, togglePopover }">
              <div
                class="w-100 input-arrow me-3 my-1 d-flex align-items-center"
                @click="togglePopover"
              >
                <div
                  class="date-show w-100 d-flex justify-content-between align-items-center"
                  style="cursor: pointer;"
                >
                  <p class="m-0" style="user-select: none;">
                    {{ inputValue }}
                  </p>
                  <img
                    src="../../assets/Depreciation/blue-calendar.svg"
                    alt=""
                  />
                </div>
              </div>
            </template>
          </date-picker>
          <div v-if="!isSameDay" class="d-flex justify-content-center">
            <button @click="cancelChange" class="cancel-button">
              {{ $t("btn.canc") }}
            </button>
            <button class="confirm-button" @click="updateAccountingPeriod">
              {{ $t("btn.save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    {{ selectedDate }}
  </div>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import { authHeader } from "../../store/actions";
import Loading from "vue-loading-overlay";
import moment from "moment";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { mapGetters } from "vuex";
import baseUrl from "../../util/backend";
export default {
  name: "AccountPeriod",
  components: {
    DatePicker,
    Loading,
  },
  data() {
    return {
      id_company: null,
      DISABLED_DATES: [
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
        10,
        11,
        12,
        13,
        14,
        15,
        16,
        17,
        18,
        19,
        20,
        21,
        22,
        23,
        24,
        25,
        26,
        27,
        28,
        29,
        30,
        31,
      ],
      isLoading: false,
      company_id: null,
      accountDate: moment().format(),
      selectedDate: moment()
        .set("date", 1)
        .format(),
      masks: {
        input: "DD/MM/YYYY",
      },
    };
  },
  filters: {
    formatDate(date) {
      return date ? moment(date).format("DD/MM/YYYY") : "";
    },
  },
  computed: {
    ...mapGetters({
      companyName: "companyName",
    }),
    isSameDay() {
      return moment(this.accountDate).isSame(moment(this.selectedDate), "day");
    },
  },

  methods: {
    moment,
    cancelChange() {
      this.selectedDate = this.accountDate;
    },
    confirmChange() {
      alert("not finished yet");
    },
    async updateAccountingPeriod() {
      this.isLoading = true;
      try {
        const res = await axios.put(
          `${baseUrl()}company/updateAccountingPeriod/${this.id_company}`,
          {
            accounting_period: moment(this.selectedDate).toISOString(),
          },
          authHeader()
        );
        Swal.fire({
          icon: "success",
          title: this.$t("dialog.success"),
          showConfirmButton: false,
          timer: 2000,
        });
        this.getCompanyData();
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.isLoading = false;
    },
    async getCompanyData() {
      this.isLoading = true;
      this.id_company = await this.$store.dispatch("getCompanyIdCookie");
      try {
        const res = await axios.get(
          `${baseUrl()}company/getCompany/${this.id_company}`,
          authHeader()
        );
        this.accountDate = res.data.data.accounting_period;
        this.selectedDate = res.data.data.accounting_period;
      } catch (err) {
        if (err.response) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.getCompanyData();
  },
};
</script>

<style lang="scss" scoped>
input,
select {
  height: 45px;
}

.manage-accounting {
  text-align: left;
}

.company-name {
  font-size: 18px;
}

@media only screen and (min-width: 1440px) {
  .main {
    overflow-x: scroll;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
}

.account-card {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 15px;
  margin: 1.5rem 0;
  max-width: 480px;
}

.date-show {
  border: 1px solid #017afd;
  border-radius: 8px;
  padding: 12px 16px;
}

.confirm-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%);
  border-radius: 10px;
  border: none;

  &-red {
    background: #ed1616;
  }
}

.cancel-button {
  text-align: center;
  display: block;
  color: #fff;
  padding: 10px 15px;
  margin: 30px 10px 10px 10px;
  width: 100%;
  max-width: 130px;
  background: #888;
  border-radius: 10px;
  border: none;
}

/*
  Animation
*/

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
